import React from 'react'
import PropTypes from 'prop-types'
import TextInput from 'components/TextInput'
import isEmpty from 'lodash.isempty'

const kWh = {
  value: 'kWhElectricity',
  label: 'kWh Strom',
}

const units = {
  oil: [
    { value: 'heatingOilInLiter', label: 'Liter Heizöl' },
    { value: 'heatingOilInKg', label: 'kg Heizöl' },
  ],
  gas: [
    { value: 'gasInM3', label: 'm3 Gas' },
    { value: 'gasInKWh', label: 'kWh Gas' },
  ],
  wood: [
    {
      value: 'sterePieceWoodConiferousWood',
      label: 'Ster Stückholz (Nadelholz)',
    },
    {
      value: 'sterePieceWoodBeechOak',
      label: 'Ster Stückholz (Buche/Eiche)',
    },
    { value: 'sterePieceWoodMix', label: 'Ster Stückholz (Mix)' },
    {
      value: 'woodChipsInKgConiferousWood',
      label: 'kg Holzschnitzel (Nadelholz)',
    },
    {
      value: 'woodChipsInKgBeechOak',
      label: 'kg Holzschnitzel Buche/Eiche)',
    },
    {
      value: 'woodChipsInM3ConiferousWood',
      label: 'm3 Holzschnitzel (Nadelholz)',
    },
    {
      value: 'woodChipsInM3BeechOak',
      label: 'm3 Holzschnitzel (Buche/Eiche)',
    },
    { value: 'pelletsInKg', label: 'kg Pellets' },
    { value: 'pelletsInM3', label: 'm3 Pellets' },
  ],
  electricalStorage: [kWh],
  airOrWaterHeatPump: [kWh],
  geothermalProbeHeatPump: [kWh],
}

const isValidEmail = /^\w[\w.-\\+]*@([\w-]+\.)+[\w-]+$/

class OfferForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.inputs = []
  }

  attachInput(input) {
    this.inputs.push(input)
  }

  detachInput(input) {
    this.inputs = this.inputs.filter(attachedInput => attachedInput !== input)
  }

  handleSubmit(e) {
    const valid = this.inputs.map(input => input.validate()).every(Boolean)

    if (!valid) {
      e.preventDefault()
      return false
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  buildIcon(field) {
    const value = this.state[field]
    if (typeof value === 'undefined' || value === null) return null

    if (String(value).length > 0) {
      return <span className="icon-valid" />
    }

    return null
  }

  validateEmail(email) {
    if (isEmpty(email)) return ['muss ausgefüllt sein']
    if (!isValidEmail.test(email)) return ['keine gültige Email Adresse']
    return []
  }

  getChildContext() {
    return {
      attachInput: this.attachInput.bind(this),
      detachInput: this.detachInput.bind(this),
    }
  }

  render() {
    const possibleUnits = units[this.state.radiatorType]
    let unitSelect = null
    if (possibleUnits) {
      unitSelect = (
        <select
          name="consumptionUnit"
          id="consumptionUnit"
          className="hm-select"
        >
          {possibleUnits.map(unit => {
            return (
              <option key={unit.value} value={unit.value}>
                {unit.label}
                &#x200E;
              </option>
            )
          })}
        </select>
      )
    }

    return (
      <form
        className="hm-form"
        action={this.props.target}
        method="POST"
        onChange={e => this.handleChange(e)}
        onSubmit={e => this.handleSubmit(e)}
      >
        <div className="section section--padded-big">
          <div className="hm-form__config">
            <input type="hidden" name="locale" id="locale" value="de" />
            <h2 className="headline">Hausinstallation</h2>
            <div className="hm-form__section hm-form__radio">
              <div className="hm-form--sub-headline">
                <h3>Was hatten sie bisher für eine Heizung?</h3>
              </div>

              <input type="radio" name="radiatorType" value="oil" id="oil" />
              <label htmlFor="oil">Öl</label>

              <input type="radio" name="radiatorType" value="gas" id="gas" />
              <label htmlFor="gas">Erdgas</label>

              <input type="radio" name="radiatorType" value="wood" id="wood" />
              <label htmlFor="wood">Holz</label>

              <input
                type="radio"
                name="radiatorType"
                value="electricalStorage"
                id="electricalStorage"
              />
              <label htmlFor="electricalStorage">Elektrospeicher</label>

              <input
                type="radio"
                name="radiatorType"
                value="airOrWaterHeatPump"
                id="airOrWaterHeatPump"
              />
              <label htmlFor="airOrWaterHeatPump">
                Luft/Wasser Wärmepumpen
              </label>

              <input
                type="radio"
                name="radiatorType"
                value="geothermalProbeHeatPump"
                id="radioatorTypeGeothermalProbeHeatPump"
              />
              <label htmlFor="radioatorTypeGeothermalProbeHeatPump">
                Erdsonden Wärmepumpen
              </label>

              <input
                type="radio"
                name="radiatorType"
                value="other"
                id="otherRadiatorType"
              />
              <label htmlFor="otherRadiatorType">Andere</label>
            </div>

            <div className="hm-form__section hm-form__radio">
              <div className="hm-form--sub-headline">
                <h3>Wie wurde bisher das Warmwasser aufbereitet?</h3>
              </div>

              <input
                type="radio"
                name="hotWaterProcessing"
                value="electricWaterHeater"
                id="electricWaterHeater"
              />
              <label htmlFor="electricWaterHeater">Elektroboiler</label>

              <input
                type="radio"
                name="hotWaterProcessing"
                value="radiator"
                id="radiator"
              />
              <label htmlFor="radiator">Mit der Heizung</label>

              <input
                type="radio"
                name="hotWaterProcessing"
                value="heatPumpWaterHeater"
                id="heatPumpWaterHeater"
              />
              <label htmlFor="heatPumpWaterHeater">Wärmepumpenboiler</label>

              <input
                type="radio"
                name="hotWaterProcessing"
                value="solarThermalEnergy"
                id="solarThermalEnergy"
              />
              <label htmlFor="solarThermalEnergy">Solarthermie</label>

              <input
                type="radio"
                name="hotWaterProcessing"
                value="other"
                id="otherHotWaterProcessing"
              />
              <label htmlFor="otherHotWaterProcessing">Andere</label>
            </div>

            <div className="hm-form__section hm-form__checkbox">
              <div className="hm-form--sub-headline">
                <h3>Welches Heizverteilsystem haben Sie?</h3>
              </div>

              <input
                type="checkbox"
                name="heatDistributionTypes[]"
                value="radiators"
                id="radiators"
              />
              <label htmlFor="radiators">Radiatoren</label>

              <input
                type="checkbox"
                name="heatDistributionTypes[]"
                value="floorHeating"
                id="floorHeating"
              />
              <label htmlFor="floorHeating">Bodenheizung</label>

              <input
                type="checkbox"
                name="heatDistributionTypes[]"
                value="otherDistributionType"
                id="otherDistributionType"
              />
              <label htmlFor="otherDistributionType">Andere</label>
            </div>

            <h2 className="headline">Verbrauch</h2>

            <div className="hm-form__section">
              <div className="hm-form--sub-headline">
                <h3>Wieviel Verbrauch hatten Sie mit der alten Heizung?</h3>
              </div>
              <div className="hm-form__input-group">
                <input
                  type="number"
                  name="consumption"
                  id="consumption"
                  placeholder="0"
                  min="0"
                />
                {unitSelect}
                {this.buildIcon('consumption')}
              </div>
            </div>

            <div className="hm-form__section">
              <div className="hm-form--sub-headline">
                <h3>Wie viel Fläche wird bei Ihnen benutzt?</h3>
              </div>
              <div className="hm-input--wrap hm-form--half hm-form--input-addon center">
                <input
                  type="number"
                  name="usedArea"
                  id="usedArea"
                  placeholder="Bitte eingeben"
                />
                <span className="input-addon--info">
                  m<sup>2</sup>
                </span>
                {this.buildIcon('usedArea')}
              </div>
            </div>

            <div className="hm-form__section">
              <div className="hm-form--sub-headline">
                <h3>Wie viele Personen brauchen Warmwasser?</h3>
              </div>
              <div className="hm-input--wrap hm-form--half center">
                <input
                  type="number"
                  min="0"
                  max="99"
                  step="1"
                  id="personsInHousehold"
                  name="personsInHousehold"
                />
                {this.buildIcon('personsInHousehold')}
              </div>
            </div>

            <h2 className="headline">Ergänzungen</h2>
            <div className="hm-form__section">
              <div className="hm-form--labeled">
                <label htmlFor="timeForReplacement">
                  Der Zeitpunkt für die Installation ist:
                </label>
                <select
                  name="timeForReplacement"
                  id="timeForReplacement"
                  className="hm-select hm-form--half"
                >
                  <option value="">Bitte wählen</option>
                  <option value="rightNow">möglichst schnell</option>
                  <option value="withinTheNextTime">
                    in den nächsten 12 Monaten
                  </option>
                  <option value="inTheNextYears">in ein paar Jahren</option>
                </select>
                {this.buildIcon('timeForReplacement')}
              </div>

              <div className="hm-form--labeled">
                <label htmlFor="manufacturer">
                  Ich bevorzuge ein System der Marke:
                </label>
                <select
                  name="manufacturer"
                  id="manufacturer"
                  className="hm-select hm-form--half"
                >
                  <option value="">Bitte wählen</option>
                  <option>Stiebel Eltron</option>
                  <option>Alpha Innotec</option>
                  <option>Viessmann</option>
                  <option>Bosch</option>
                  <option>Vaillant</option>
                </select>
                {this.buildIcon('manufacturer')}
              </div>

              <div className="hm-form--labeled">
                <label htmlFor="price">Bei den Geräten ist mir wichtig:</label>
                <select
                  name="price"
                  id="price"
                  className="hm-select hm-form--half"
                >
                  <option value="">Bitte wählen</option>
                  <option value="qualityIsMoreImportant">beste Qualität</option>
                  <option value="goodCostBenefitRatio">
                    ein gutes Preis-Leistungs-Verhältnis
                  </option>
                  <option value="lowPrice">ein günstiger Preis</option>
                </select>
                {this.buildIcon('price')}
              </div>

              <div className="hm-form--labeled">
                <label htmlFor="heatPumpLocation">
                  Die Wärmepumpe kann aufgestellt werden:
                </label>
                <select
                  name="heatPumpLocation"
                  id="heatPumpLocation"
                  className="hm-select hm-form--half"
                >
                  <option value="">Bitte wählen</option>
                  <option value="outdoorInstallation">draussen</option>
                  <option value="indoorInstallation">
                    innen mit Lichtschächten
                  </option>
                  <option value="doesNotMatter">weiss nicht</option>
                </select>
                {this.buildIcon('heatPumpLocation')}
              </div>

              <div className="hm-form--labeled">
                <label htmlFor="spaceInTheBasement">
                  Der Platz in unserem Keller:
                </label>
                <select
                  name="spaceInTheBasement"
                  id="spaceInTheBasement"
                  className="hm-select hm-form--half"
                >
                  <option value="">Bitte wählen</option>
                  <option value="enoughSpaceAvailable">
                    genug Platz vorhanden
                  </option>
                  <option value="fewSpaceSystemShouldBeSmall">
                    knapp, möglichst wenig Platz verbrauchen
                  </option>
                  <option value="doesNotMatter">weiss nicht</option>
                </select>
                {this.buildIcon('spaceInTheBasement')}
              </div>

              <div className="hm-form__section hm-form__checkbox">
                <div className="hm-form--sub-headline">
                  <h3>Zusätzlich interesse ich mich für:</h3>
                </div>

                <input
                  type="checkbox"
                  name="enhancements[]"
                  value="pool"
                  id="pool"
                />
                <label htmlFor="pool">Poolheizung</label>

                <input
                  type="checkbox"
                  name="enhancements[]"
                  value="solarHeat"
                  id="solarHeat"
                />
                <label htmlFor="solarHeat">Solarthermie</label>

                <input
                  type="checkbox"
                  name="enhancements[]"
                  value="photovoltaics"
                  id="photovoltaics"
                />
                <label htmlFor="photovoltaics">Photovoltaikanlage</label>

                <input
                  type="checkbox"
                  name="enhancements[]"
                  value="batteryStorage"
                  id="batteryStorage"
                />
                <label htmlFor="batteryStorage">Batteriespeicher</label>

                <input
                  type="checkbox"
                  name="enhancements[]"
                  value="otherEnhancements"
                  id="otherEnhancements"
                />
                <label htmlFor="otherEnhancements">
                  weiteres (bitte in Notizen erwähnen)
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="section section--padded-big section--bg-primary">
          <div className="hm-form__contact">
            <h2 className="headline">Kontakt</h2>
            <div className="hm-form__section column--measure-medium center">
              <div className="hm-input hm-form--half">
                <TextInput name="firstName" label="Vorname" />
              </div>

              <div className="hm-input hm-form--half">
                <TextInput name="lastName" label="Nachname" />
              </div>

              <div className="hm-input hm-form--stretch">
                <TextInput name="street" label="Straße und Nummer" />
              </div>

              <div className="hm-input hm-form--one-third">
                <TextInput
                  name="zipCode"
                  label="PLZ"
                  type="number"
                  min="0000"
                  max="9999"
                />
              </div>

              <div className="hm-input hm-form--two-thirds">
                <TextInput name="city" label="Ort" />
              </div>

              <div className="hm-input hm-form--half">
                <TextInput
                  name="email"
                  label="E-Mail"
                  validator={email => this.validateEmail(email)}
                />
              </div>

              <div className="hm-input hm-form--half">
                <TextInput name="phone" type="tel" label="Telefon" />
              </div>

              <div className="hm-input hm-form--stretch">
                <textarea
                  name="notes"
                  placeholder="Ihre Nachricht"
                  aria-label="Ihre Nachricht"
                />
                {this.buildIcon('notes')}
              </div>
              <div className="w-100 tr">
                <button className="btn btn--primary">Senden</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default OfferForm

OfferForm.propTypes = {
  target: PropTypes.string.isRequired,
}

OfferForm.childContextTypes = {
  attachInput: PropTypes.func,
  detachInput: PropTypes.func,
}
