// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import OfferForm from 'components/Contentful/OfferForm'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Ölheizung ersetzen, Kosten Wärmepumpe: Kostenlose Offerte"
        meta={[
          { name: 'description', content: 'Wie viel kostet der Ersatz einer Ölheizung? Was sind die Preise für eine neue Luft-Wasser-Wärmepumpe in der Schweiz? Heizöl und Ölheizungen - Preisvergleich.' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='1T4mLjUhtG8WOe2mc6W0oe-2z68DNnXAkyyYqk8sGGeSI'
          id=''
        >
          <OfferForm
            {...{
    "type": "offerForm",
    "id": "2z68DNnXAkyyYqk8sGGeSI",
    "updatedAt": "2018-04-03T12:00:30.819Z",
    "title": "Offerten Formular",
    "target": "https://go.heizungsmacher.ch/l/503181/2018-03-15/sq2f5"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
