import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash.isempty'

class TextInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      value: props.value || '',
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.context.attachInput(this)
  }

  componentWillUnmount() {
    this.context.detachInput(this)
  }

  handleChange(e) {
    this.setState({ value: e.target.value }, () => this.validate())
  }

  validate() {
    let errors = []

    if (this.props.validator) {
      errors = this.props.validator(this.state.value)
    }

    this.setState({ errors })
    return errors.length === 0
  }

  render() {
    const { name, label, type } = this.props
    const { value } = this.state
    const errors = (this.state.errors || []).map(error => {
      return (
        <span key={`${name}-${error}`} className="error">
          {error}
        </span>
      )
    })

    let icon = null
    if (!isEmpty(value) && errors.length === 0) {
      icon = <span className="icon-valid" />
    } else if (errors.length > 0) {
      icon = <span className="icon-invalid" />
    }

    const inputProps = {
      type: type || 'text',
      name: name,
      placeholder: label,
      'aria-label': label,
      value: value,
      onChange: e => this.handleChange(e),
    }

    if (this.props.max) inputProps.max = this.props.max
    if (this.props.min) inputProps.min = this.props.min

    return (
      <Fragment>
        <input {...inputProps} />
        {errors}
        {icon}
      </Fragment>
    )
  }
}

export default TextInput

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  validator: PropTypes.func,
}

TextInput.contextTypes = {
  attachInput: PropTypes.func,
  detachInput: PropTypes.func,
}
